export default function MyTagsLogo ({ accentColor = '#F22D31', baseColor = '#181818', iconOnly = false, dotsTopRight = true, dotsBottomRight = true, dotsBottomLeft = true, dotsTopLeft = true }) {
  return (
    <svg clipRule="evenodd" fillRule="evenodd" strokeMiterlimit="5" version="1.1" viewBox={`0 0 ${ iconOnly ? 34 : 142 } 34`} xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
      { !iconOnly ? [
        <g key='lettering1' transform="translate(-160 -24)">
          <path d="m294.25 49.25c-1.083 0-2.108-0.087-3.075-0.263-0.967-0.174-1.792-0.42-2.475-0.737v-3.875c0.75 0.35 1.583 0.633 2.5 0.85s1.775 0.325 2.575 0.325c0.833 0 1.463-0.071 1.888-0.212 0.425-0.142 0.637-0.463 0.637-0.963 0-0.35-0.121-0.625-0.362-0.825-0.242-0.2-0.617-0.383-1.125-0.55-0.509-0.167-1.171-0.375-1.988-0.625-1.083-0.35-1.942-0.733-2.575-1.15s-1.087-0.925-1.362-1.525-0.413-1.333-0.413-2.2c0-1.583 0.563-2.787 1.688-3.612s2.795-1.238 5.012-1.238c0.883 0 1.796 0.075 2.738 0.225 0.941 0.15 1.704 0.317 2.287 0.5v3.9c-0.783-0.317-1.533-0.542-2.25-0.675s-1.392-0.2-2.025-0.2c-0.733 0-1.358 0.058-1.875 0.175s-0.775 0.417-0.775 0.9c0 0.417 0.221 0.721 0.663 0.913 0.441 0.191 1.229 0.437 2.362 0.737 1.317 0.35 2.321 0.775 3.013 1.275 0.691 0.5 1.162 1.079 1.412 1.738 0.25 0.658 0.375 1.404 0.375 2.237 0 1.467-0.567 2.646-1.7 3.538-1.133 0.891-2.85 1.337-5.15 1.337zm-13.75 0c-2.867 0-5.05-0.704-6.55-2.112-1.5-1.409-2.25-3.421-2.25-6.038 0-2.7 0.775-4.783 2.325-6.25s3.817-2.2 6.8-2.2c1 0 1.904 0.058 2.712 0.175 0.809 0.117 1.571 0.292 2.288 0.525v4.025c-0.733-0.283-1.492-0.475-2.275-0.575s-1.583-0.15-2.4-0.15c-1.55 0-2.712 0.35-3.488 1.05-0.774 0.7-1.162 1.833-1.162 3.4 0 1.467 0.338 2.525 1.013 3.175s1.67 0.975 2.987 0.975c0.433 0 0.842-0.029 1.225-0.087 0.383-0.059 0.742-0.138 1.075-0.238v-2.35h-3.2v-2.9h7.125v8.1c-0.617 0.383-1.438 0.725-2.462 1.025-1.025 0.3-2.28 0.45-3.763 0.45zm-34.85-0.25v-12.6h-4.525v-3.5h13.75v3.5h-4.525v12.6h-4.7zm8.35 0 6.175-16.1h4.975l6.175 16.1h-4.9l-1.35-3.425h-4.85l-1.325 3.425h-4.9zm6.85-6.8h3.6l-1.8-4.725-1.8 4.725z" fill={accentColor} fillRule="nonzero"/>
        </g>,
        <g key='lettering2' transform="matrix(1,0,0,-1,43,29.9)">
          <clipPath id="t">
            <path d="m1.125 4.9v16.1h4.575l4-8.175 3.975 8.175h4.55v-16.1h-4.7v8.05l-2.575-5.3h-2.525l-2.6 5.3v-8.05h-4.7zm24.65 0v5.9l-5.8 10.2h4.9l3.25-6.075 3.25 6.075h4.9l-5.8-10.2v-5.9h-4.7z" clipRule="nonzero"/>
          </clipPath>
          <g clipPath="url(#t)">
            <path d="m1.125 4.9v16.1h4.575l4-8.175 3.975 8.175h4.55v-16.1h-4.7v8.05l-2.575-5.3h-2.525l-2.6 5.3v-8.05h-4.7zm24.65 0v5.9l-5.8 10.2h4.9l3.25-6.075 3.25 6.075h4.9l-5.8-10.2v-5.9h-4.7z" fill="none" stroke={baseColor} strokeWidth="2px"/>
          </g>
        </g>
      ] : '' }
      <g transform="translate(-160 -24)">
        <path d="m171 47h4v2h-6v-6h2v4zm12 0v-4h2v6h-6v-2h4zm-8-2h-2v-8h4v2h-2v2h2v-2h2v-2h2v4h-2v2h2v2h-4v-2h-2v2zm0-12v2h-4v4h-2v-6h6zm10 6h-2v-4h-4v-2h6v6z" fill={baseColor}/>
      </g>
      {dotsTopLeft && (
        <g transform="translate(1 -27)">
          <clipPath id="i">
            <path d="m2 32c1.105 0 2-0.895 2-2s-0.895-2-2-2-2 0.895-2 2 0.895 2 2 2z"/>
          </clipPath>
          <g clipPath="url(#i)">
            <rect x="-1" y="27" width="6" height="6" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsBottomLeft && (
        <g transform="translate(1 9)">
          <clipPath id="g">
            <path d="m4 14c1.105 0 2-0.895 2-2s-0.895-2-2-2-2 0.895-2 2 0.895 2 2 2z"/>
          </clipPath>
          <g clipPath="url(#g)">
            <rect x="1" y="9" width="6" height="6" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsBottomLeft && (
        <g transform="translate(1 29)">
          <clipPath id="f">
            <path d="m2 4c1.105 0 2-0.895 2-2s-0.895-2-2-2-2 0.895-2 2 0.895 2 2 2z"/>
          </clipPath>
          <g clipPath="url(#f)">
            <rect x="-1" y="-1" width="6" height="6" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsBottomRight && (
        <g transform="translate(1 13)">
          <clipPath id="e">
            <path d="m30 12c1.105 0 2-0.895 2-2s-0.895-2-2-2-2 0.895-2 2 0.895 2 2 2z"/>
          </clipPath>
          <g clipPath="url(#e)">
            <rect x="27" y="7" width="6" height="6" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsTopLeft && (
        <g transform="translate(1 -25)">
          <clipPath id="d">
            <path d="m9 30c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#d)">
            <rect x="7" y="27" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsTopRight && (
        <g transform="translate(1 -13)">
          <clipPath id="c">
            <path d="m27 24c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#c)">
            <rect x="25" y="21" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsTopRight && (
        <g transform="translate(1 -21)">
          <clipPath id="b">
            <path d="m23 28c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#b)">
            <rect x="21" y="25" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsTopLeft && (
        <g transform="translate(1 -1)">
          <clipPath id="a">
            <path d="m5 18c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#a)">
            <rect x="3" y="15" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsTopRight && (
        <g transform="translate(1 -29)">
          <clipPath id="s">
            <path d="m17 32c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#s)">
            <rect x="15" y="29" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {(dotsTopRight || dotsTopLeft) && (
        <g transform="translate(1 -21)">
          <clipPath id="r">
            <path d="m15 28c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#r)">
            <rect x="13" y="25" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsBottomLeft && (
        <g transform="translate(1 23)">
          <clipPath id="q">
            <path d="m7 6c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#q)">
            <rect x="5" y="3" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsBottomLeft && (
        <g transform="translate(1 31)">
          <clipPath id="p">
            <path d="m15 2c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#p)">
            <rect x="13" y="-1" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsBottomRight && (
        <g transform="translate(1 23)">
          <clipPath id="o">
            <path d="m27 6c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#o)">
            <rect x="25" y="3" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsTopRight && (
        <g transform="translate(1 -1)">
          <clipPath id="n">
            <path d="m31 18c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#n)">
            <rect x="29" y="15" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsBottomRight && (
        <g transform="translate(1 31)">
          <clipPath id="m">
            <path d="m31 2c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#m)">
            <rect x="29" y="-1" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {(dotsTopRight || dotsBottomRight) && (
        <g transform="translate(1 3)">
          <clipPath id="l">
            <path d="m27 16c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"/>
          </clipPath>
          <g clipPath="url(#l)">
            <rect x="25" y="13" width="4" height="4" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsTopRight && (
        <g transform="translate(1 -25)">
          <clipPath id="k">
            <path d="m29 32c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"/>
          </clipPath>
          <g clipPath="url(#k)">
            <rect x="25" y="25" width="8" height="8" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsTopLeft && (
        <g transform="translate(1 -13)">
          <clipPath id="j">
            <path d="m3 26c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"/>
          </clipPath>
          <g clipPath="url(#j)">
            <rect x="-1" y="19" width="8" height="8" fill={accentColor}/>
          </g>
        </g>
      )}
      {dotsBottomRight && (
        <g transform="translate(1 27)">
          <clipPath id="h">
            <path d="m21 6c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"/>
          </clipPath>
          <g clipPath="url(#h)">
            <rect x="17" y="-1" width="8" height="8" fill={accentColor}/>
          </g>
        </g>
      )}
    </svg>
  )
}